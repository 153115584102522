
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[contextHash]/category-news/bright-podcast",
      function () {
        return require("private-next-pages/[contextHash]/category-news/bright-podcast/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[contextHash]/category-news/bright-podcast"])
      });
    }
  