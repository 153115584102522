/**
 * Since we can't switch to the app router yet, this is a fallback file for the pages router
 * See https://pxr-tech.atlassian.net/browse/PB-6306 for more details
 */

import { prepareProps } from '@web/_app/[contextHash]/category-news/bright-podcast/prepareProps';
import { Route } from '@web/routing';
import { getPagesStaticPaths, getPagesStaticPropsWrapper } from '@web/routing/getPagesStaticPropsWrapper';
import { CategoryNews, Props } from '@web/templates/CategoryNews';

export const getStaticPaths = getPagesStaticPaths;
export const getStaticProps = getPagesStaticPropsWrapper<Props>(prepareProps, Route.BrightPodcast);

export default CategoryNews;
